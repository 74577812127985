.uploadImageDiv {
   
    background: #D9D9D9;
    border-radius: 12px;
    margin: 0px 0px 20px;
    padding: 20px;
    height: auto;
    text-align: center;
    position: relative;
    min-height: 121px;
    max-height: 450px;

}
.postHousingUploadImage img{
    height: 32px;
    cursor: pointer;
}

.postHousingUploadImage p{
    font-family: 'Inter';
font-style: italic;
font-weight: 700;
font-size: 14.7px;
line-height: 18px;
padding-top: 10px !important;
color: #98A8C4;
cursor: pointer;
}
.imageContainerDiv{
 
    /* position: absolute; */
    top: 2px;
    display: flex;
    flex-wrap: wrap;
}
.imageContainerDiv img{
    height: 75px;
    margin: 10px;
    max-width: 162px;
    object-fit: cover;
    position: relative;
    object-fit: fill;
    height: 70px;
    width: 70px;

}
.removeButton{
    bottom: 28px;
    position: relative;
    right: 25px;
    background: #fff !important;
    border-radius: 50% !important;
    height: 15px !important;
    padding: 1px;
    width: 15px;

}

.removeButton i{
    /* background: #fff;
    border-radius: 50%;
    height: 15px;
    padding: 1px;

    width: 15px; */
    font-size: 10px;
    position: absolute;
    top: 1px;
    right: 2px;
}

