.mainBlogContainer {
  background-image: url("../../images/blog/blogCover.png");
  background-size: cover;
  /* aspect-ratio: 7/2; */
  width: 100%;
  /* aspect-ratio: 10/3; */
  background-position: bottom;
  padding-bottom: 13px;
}

.mainBlogImage {
  /* position: relative; */
}

.mainBlogImage img {
  aspect-ratio: 0/1;
  /* width: 348px; */
  object-fit: cover;
  position: relative;
  z-index: 99;
  height: 409px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  color: black !important;
}
.mainBlogSubContainer {
  position: relative;
  top: 37%;
}
.mainBlogCardDiv {
  display: flex;
  justify-content: center;
  

  /* padding-top: 5%; */
}
.rightMainBlog {
  background-color: #ebebeb;
  width: 597px;
  height: 353px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  /* position: relative;
  top: 24px; */
  padding: 20px;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
}
.dateClass {
  color: #999999;
  font-size: 14px;
}
.readMoreDiv {
  display: flex;
  justify-content: flex-end;
}

.readMoreDiv p {
  color: #2b613f;

 
}


.readMoreDiv p:hover {
  border-bottom: 1px #2b613f solid;
    width: fit-content;
    cursor: pointer;
}



.mainCardParagraph {
  overflow: hidden;
  text-overflow: ellipsis;
  height: 171px;
}

@media (min-width: 900px) and (max-width: 1250px) {
  .mainBlogImage img {
    height: 357px;
  }

  .rightMainBlog {
    width: 531px;
    height: 290px;
    position: relative;
    top: 32px;
  }
  .mainCardParagraph {
    height: 101px;
  }
  .mainBlogSubContainer{
    position: relative;
    top: 90px !important;
  }
  .mainBlogCardDiv{
    width: 90% !important;
    margin: auto !important;
    position: relative;
    top: 1px !important;
    padding-top: 52px;
  }
  .mainBlogImage{
    height: 302px;
  }
}

@media (min-width: 480px) and (max-width: 900px) {
  .mainBlogSubContainer {
    display: flex;
    justify-content: center;
  }

  .mainBlogCardDiv {
    display: flex;
    flex-direction: column !important;
  }
  .mainBlogImage {
    margin: auto;
  }
  .mainBlogImage img {
    aspect-ratio: 6/5;
    height: 316px;
  }
  .rightMainBlog {
    position: relative;
    bottom: 42px !important;
  }
  .mainCardParagraph {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 40rem;
  }
  
}

@media (max-width: 480px) {
    .mainBlogContainer{
        height: 315px;
        
    }
  .mainBlogSubContainer {
    display: flex;
    justify-content: center;
    position: relative;
    top: 213px;
  }
  .mainBlogCardDiv {
    display: flex;
    flex-direction: column !important;
  }
  .rightMainBlog{
    /* width: 284px;
    height: 246px;
    position: relative;
    bottom: 65px; */

    padding-top: 94px;
    width: 284px;
    height: 246px;
    position: relative;
    bottom: 83px;


  }
  .mainCardParagraph {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 40rem;
    height: 21px;

  }
  .rightMainBlog h5{
    font-size: 15px;
  }
  .mainBlogImage{
    margin: auto;
  }
  .mainBlogImage img {
    aspect-ratio: 1/1;
    height: 229px;
   
  }

}

@media (min-width: 1100px) {
  .rightMainBlog {
    position: relative;
    top: 24px;
  }
  .mainBlogCardDiv{
    position: relative;
  top: 146px;
  padding-top: 52px;

  
  }
}

@media (min-width: 480px) and (max-width: 600px) {
  .rightMainBlog{
    width: 472px;
    height: 353px;
  }
}


@media (min-width : 768px) and (max-width : 890px){
  .rightMainBlog{
  padding-top: 57px;
}


}

@media (min-width: 481px) and (max-width: 900px ){
  .mainBlogSubContainer{
    position: relative;
    top: 162px;
  }
  .rightMainBlog{
    width: 533px;
    padding-top: 43px;
  }
}