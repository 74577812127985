@import "../_App.module.scss";

.interestedMainDiv {
  padding: 25px 0;
  box-shadow: 5px -7px 4px rgba(0, 0, 0, 0.25);

  .interestedContainer {
    overflow: hidden;
    height: 370px;

    .interestedH3 {
      margin: 0px 0 20px;
    }
    .interestedCard {
      padding: 10px 12px;
      margin: auto;
      
      .interestedImg {
        width: 100%;
        height: 230px;
        border-radius: 14px;
        object-fit: cover;
      }
      .interestedTitle {
        font-size: 20px;
        color: $black;
        margin: 15px 0px 5px;
        height: 30px;
        overflow: hidden;
      }
      .interestedDescription {
        font-size: 14px;
        color: $primary-color;
        margin: 0px 0px 5px;
        height: 22px;
        overflow: hidden;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .interestedMainDiv {
    .interestedH3 {
      font-size: 21px;
    }

    .interestedCard {
      max-width: 180px !important;
      margin: auto;

      .interestedImg {
        height: 200px;
      }
      .interestedTitle {
        font-size: 17px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .interestedMainDiv {
    .interestedCard {
      max-width: 160px !important;
      margin: auto;

      .interestedImg {
        height: 180px;
      }
    }
  }
}
