@import "../_App.module.scss";

.mainCategoryCard {
  height: 314px;
  padding: 20px 20px !important;
  max-width: 280px !important;
  min-width: 192px;
  overflow: hidden;

  .categoryCardBody {
    padding: 0;
    border-radius: 14px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: #f0f0f0;
    color: $primary-color;
    &:hover {
      scale: 1.1;
      transition: 0.3s;
      cursor: pointer;
    }
    .categoryImage {
      width: 100%;
      height: 192px;
      padding: 15px;
      border-radius: 30px;
      object-fit: cover;
    }
    .categoryMainInfoBox {
      padding: 0px 10px;

      .categoryInfo {
        .categoryTitle {
          margin: 0;
          height: 30px;
          overflow: hidden;
          text-align: center;
        }
        .shopTitle {
          margin: 10px 0;
          padding: 5px;
          height: 55px;
          overflow: hidden;
          text-align: left;
          font-size: 20px;
        }
        .categoryDescription {
          margin: 0;
          padding: 10px 5px 25px;
          text-align: center;
          color: $primary-color;
          font-size: 12px;
        }
      }
      .storeAction {
        display: flex;
        justify-content: space-around;
        padding: 18px 0;

        i {
          cursor: pointer;
        }

        .favIconColor {
          color: $primary-color;
        }
      }
    }
  }
}

.filterResult {
  color: $primary-color;
  border: 2px solid $primary-color !important;
  width: fit-content !important;
  text-align: center;
  padding: 10px 18px !important;
  margin: 15px 5px 10px !important;
  font-size: 16px;
  font-weight: 500;
  position: relative;

  i {
    position: absolute;
    font-size: 14px;
    top: 3px;
    cursor: pointer;
  }
}

@media screen and (max-width: 1200px) {
  .mainCategoryCard {
    margin: auto;
    min-width: 200px;
    height: 315px;
    padding: 20px 8px !important;
    max-width: 230px !important;

    .categoryCardBody {
      .categoryImage {
        width: 100%;
        height: 200px;
        padding: 10px;
      }
    }
  }
}

@media (max-width: 490px) {
  .mainCategoryCard {
    margin: auto;
    min-width: 130px;
    height: 315px;
    padding: 20px 8px !important;
    max-width: 185px !important;
  }
}
@media (max-width: 400px) {
  .mainCategoryCard {
    margin: auto;
    min-width: 130px;
    height: 300px;
    padding: 20px 8px !important;
    max-width: 150px !important;

    .categoryCardBody {
      .categoryImage {
        width: 100%;
        height: 170px;
        padding: 10px;
      }
    }
  }
}

@media (max-width: 370px) {
  .mainCategoryCard {
  min-width: 166px;
}
}

@media (min-width: 370px) and (max-width: 400px) {
  .mainCategoryCard {
  min-width: 186px;
  }
}

@media (min-width: 355px ) and (max-width: 360px) {
  .mainCategoryCard {
   
    height: 308px;
  
    max-width: 136px !important;
  }
  .categoryCardBody {
    .categoryMainInfoBox {
      .categoryInfo {
        .categoryTitle {
          height: 45px !important;

          font-size: 17px !important;
        }
      }
    }
  }
}


@media (max-width: 355px) {
  .mainCategoryCard {
  max-width: 120px !important;
}
}