.findMasjidSectionContainer{

}

.findMasjidSectionTitle {
    text-align: center;
    color: #0A4422;
    margin-bottom: 40px;
    padding-top: 99px;
}
.findMasjidSectionSideTitle{
    text-align: start;
    color: #0A4422;
    margin-bottom: 40px;
    padding: 65px 7px 0px 3px;
}
.findMasjidSectionInfoContainer{
    background-color: #f1f1f1;

    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
    width: 73% !important;
    margin: auto;
    /* height: 500px; */
    /* max-height: 800px;
    min-height: 500px; */
}
.findMasjidSectionPageContainer{
    width: 100% !important;
    background-color: #f1f1f1;

    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
    margin: auto;
}
.mapDiv{
    padding-top: 28px;
    margin-bottom: 14px;

}


@media (max-width: 480px) {
    .findMasjidSectionInfoContainer{
        width: 93% !important;
}
 .findMasjidSectionInfoContainer{

    /* width: 100% !important; */
 }
}

@media (min-width: 480px) and (max-width: 586px) {
    .prayTimeInfo{
        min-width: 60px;
    }
    .findMasjidSectionInfoContainer{
      width: 85% !important;
    }
}