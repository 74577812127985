@import "../_App.module.scss";

.mainHouseCard{
    // height: 420px;
    max-height: 450px;
    padding: 20px 20px !important;
    max-width: 420px !important;
    min-width: 280px;
    overflow: hidden;

    .houseCardBody{
        padding: 0;
        border-radius: 14px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        background: $box-color;
        
        .houseImage{
            width: 100%;
            height: 250px;
            border-radius: 14px;
        }
        .housingMainInfoBox{
            padding: 0px 10px;

        .houseInfo{
            .houseTitle{
                margin: 10px 0;
                height: 30px;
                overflow: hidden;
                color: $primary-color;
            }
            .houseRooms{
                display: flex;
                justify-content: space-between;
                max-width: 85%;
                color: $highlight-color;
            }
            p{
                color: $highlight-color;
            }
        }
        .actionDiv{
            display: flex;
            justify-content: space-around;
            padding: 10px 0;
            height: 90px;

            i{
                cursor: pointer;
            }

            .favIconColor{
                color: $highlight-color;
            }
        }
        .housePrice{
            text-align: end;
            padding: 0 10px;
            color: $highlight-color;
            font-weight: bold;
        }
    }
    .housingApprovedBox{
        .approvalDiv{
            display: flex;
            padding: 0 23px;

            .waitingApproval{
                color: #CF1E50;
            }
            .published{
                color:#0A4422;
            }
            .deleteIcon{
                color: #CF1E50;
                padding: 0 12px;
                cursor: pointer;
            }
        }
    }
    }
}

.filterResult{
    color: $primary-color;
    border: 2px solid $primary-color !important;
    width: fit-content !important;
    text-align: center;
    padding: 10px 18px !important;
    margin: 15px 5px 10px !important;
    font-size: 16px;
    font-weight: 500;
    position: relative;

    i{
        position: absolute;
        font-size: 14px;
        top: 3px;
        left: 94px;
        cursor: pointer;
    }
}

@media screen and (max-width: 1200px) {
    .mainHouseCard{
        margin: auto;
        min-width: 320px;

    }
    
}