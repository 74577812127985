@import "../_App.module.scss";
.heroDiv {
  background-image: url("../../images/HomePageImages/HeroBackground.png");

  display: flex;
  align-items: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;

  height: 800px;
  .descriptionDiv {
    position: absolute;
    left: 148px;
    h3 {
      color: $primary-color;
    }
  }
}
.heroNavDiv {
  /* Shared rules */
  background: #f1f1f1 !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

  width: fit-content;

  .imageNavIcon {
    padding: 11px;
    img {
      height: 32px;
    }
  }
}
@media (min-width: 320px) and (max-width: 330px) {
  .heroDiv {
    height: 432px !important;
  }
}
@media (max-width: 600px) {
  .heroDiv {
    display: flex;
    align-items: flex-end;
    background-position: top;
    justify-content: center;
    height: 537px;
    .descriptionDiv {
      position: absolute;
      left: 20px;
      top: 143px;
      width: 50%;
    }
  }
}
.heroNavDiv {
  display: flex;
  border-radius: 9px;
  position: relative;
  bottom: 30px;
}

@media (min-width: 800px) {
  .heroNavDiv {
    /* Additional rules specific to this media query */
    border-bottom-right-radius: 9px;
    border-top-right-radius: 9px;
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 600px) and (max-width: 1203px) {
  .heroNavDiv {
    /* Additional rules specific to this media query */

    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 480px) and (max-width: 1203px) {
  .heroDiv {
    display: flex;
    align-items: end;
    background-position: top;
    justify-content: center;
    .descriptionDiv {
      position: absolute;
      left: 54px;
      top: 183px;
      width: 50%;
    }
  }
}

@media (min-width: 600px) and (max-width: 1194px) {
  .heroDiv {
    aspect-ratio: 1;
    display: flex;
    align-items: end;
    background-position: top;
    justify-content: center;
  }
}
