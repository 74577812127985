@import "../_App.module.scss";

.aboutUsContainer{
    // height: 450px;
    // display: flex;
    // justify-content: space-evenly;
    padding-top: 72px;
    .leftAboutInfo{
        color: $primary-color;
        h3{
            font-size: 33px;
            margin: 0px;
        }
        p{
            margin: 0;
            padding-top: 17px;  
            font-size: 18px;
        }
    }
    .aboutUsImageContainer {
        img{
            height: 313px;
        
        }
    }
    .btnWidth{
        width: 400px
    }
    .leftAboutInfo{
      width: 41%
    
    }
    .aboutUsBtnDiv{
        display: flex;
        justify-content: flex-end;
        padding-top: 12px;
    }
 
}

@media (max-width: 768px){
    
.aboutUsContainer{
    // min-height: 700px;
    // min-height: 473px;
    min-height: 473px;
    display: flex;
    // justify-content: space-evenly;
    padding-top: 72px;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    .leftAboutInfo{
       text-align: center;
    }
    .leftAboutInfo{
        width: 80%
    }
    .aboutUsImageContainer{
        img{
            height: 232px;
        }
    }
}
}

@media (max-width: 480px) {
    .leftAboutInfo{
        width: 85% !important;
        h3{
            font-size: 25px !important;
        }
    }

}

@media (min-width: 768px) {
  
  .aboutUsContainer{
    display: flex;
    justify-content: space-evenly;
  }
}
