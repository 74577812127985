
.masjidImageDiv img{
border-radius: 8px;
width: 100%;
    object-fit: cover;
}

  

@media (max-width: 480px) {
    .masjidImageDiv img{
        height: 205px;
    }
    .masjidImageDiv {
        overflow: hidden;
    }
}
