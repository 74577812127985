.shopGalleryImages img {
    height: 156px;
    width: 196px;
  object-fit: cover;
  margin: 7px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

}

.shopGalleryImages{
    cursor: pointer;
}

.shopGalleryContainer{
    padding-top: 35px;

}
.shopGalleryContainerDiv{
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}
.shopGalleryContainer h4{
        color: var(--primary-color);
}

@media (max-width: 480px) {
    .shopGalleryImages img {
    width: 153px;
    margin: 7px;
    border-radius: 13px;
    margin: auto;
    margin-bottom: 26px;
}
.shopGalleryContainer h4{
    padding: 0px 13px;
}
.shopGalleryContainerDiv{
    display: flex;
    justify-content: space-around;
}
}