.mainbox {
  /* background-color: #95C2DE; */
  margin: auto;
  /* height: 600px; */
  /* width: 600px; */
  position: relative;
}
.err {
  color: var(--white);
  font-family: "Nunito Sans", sans-serif;
  font-size: 11rem;
  position: absolute;
  left: 20%;
  top: 8%;
}
.far {
  position: absolute;
  font-size: 8.5rem;
  left: 42%;
  top: 15%;
  color: #ffffff;
}
.err2 {
  color: var(--primary-color);
  font-family: "Nunito Sans", sans-serif;
  font-size: 11rem;
  display: flex;
  justify-content: center;
}
.msg {
  text-align: center;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.6rem;
  /* position: absolute;
    left: 16%;
    top: 45%;
    width: 75%; */
}
a {
  text-decoration: none;
  color: white;
}
a:hover {
  text-decoration: underline;
}
.colorDiv {
  /* background-color: #95C2DE; */
  background-image: url("../images/userProfile/userProfileBackground.png") !important;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
}
.homeButton {
  color: var(--primary-color) !important;
  border-bottom: 1px var(--secondary-color) solid;
}
