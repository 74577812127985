.showBlogMainDiv{

}

.showBlogMainDiv img{
    height: auto;
    object-fit: cover;
    width: 100%;
    /* aspect-ratio: 3/1; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    height: 442px;
}

.showBlogParagraphImage img{
    height: auto;
    object-fit: cover;
    width: 100%;
    /* aspect-ratio: 3/1; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    height: 442px;
}

.mainShowBlogFirstSection h3{
    color: var(--primary-color);

}
.showBlogDate{
color: var(--highlight-color);
}
.showBlogParagraphMain h3{
    color: var(--primary-color);
}
.showBlogParagraphMain{
    padding-top: 50px;
}
.MainShowBlogContainer{
    padding-top: 50px;
    }
.showBlogParagraphContainer{
margin-bottom: 15px;
}
@media (max-width: 480px) {

    .showBlogMainDiv img{
        height: 255px;
    }
    
    .showBlogParagraphImage img{
        height: 255px;
    }
}

