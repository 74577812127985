
.inputMargin {
margin: 0px 12px 0px 0px !important;
background: rgb(217, 217, 217) !important;
border: none !important;

}

.textArea{
    background: rgb(217, 217, 217);
    height: 57px;
    border: none;
    width: 100%;
    min-height: 100px;
    border-radius: 12px;
    padding: 5px 10px;

}
.dropDownMain{
    background: #ffffff;
    border: 1px solid #0A4422;
    border-radius: 12px;
    height: 45px;
    padding: 5px 10px;
    margin: 0px 0px 20px;
    background: rgb(217, 217, 217);
    border: none;
    color: #757376;

}
.btn{
    background: #0a4422;
    border-radius: 12px;
    color: #fff;
    height: 45px;
    width: 30%;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 20px 0px;


}
.required{
    color: red;
    width: 100%;
}