// $color: black;
// $mediumGreen : #B1D7B4;
// $lightGreen : #CEDAD3;
// $darkGreen: #0A4422;
// $white : #FFFFFF;
$lightGrey: #F1F1F1;
$transparentBlack :  rgba(0,0,0,.77);
$mixLightgreenGrey: #D9D9D9;
$mediumLightGreen:  #477d4c;



$primary-color: #0A4422;
$secondary-color : #B1D7B4;
$tertiary-color: #CEDAD3; //light green
$box-color: #F1F1F1;
$header-footer-box-color: #F1F1F1;
$highlight-color: #565252;
$primary-button-font-color: #ffff;
$sub-box-color :  rgba(247, 254, 255, 0.1);
$danger-color: red;
$success-color : green;
$black: black;
$white: #ffff;

