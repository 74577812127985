.showHousingImageDiv img {
  z-index: 1;
  width: 105%;
  height: 751px;
  object-fit: cover;
  box-shadow: -8px 4px 19px rgba(0, 0, 0, 0.5);
}

.showHousingInfoDiv {
  background: #ffff;
  border-radius: 0px 0 0 32px;
  background: #ffffff;
  box-shadow: -8px 4px 19px rgba(0, 0, 0, 0.5);
  z-index: 2;
  padding: 38px !important;
}
.showHousingFirstSection {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.showHousingThirdSection {
  display: flex;
  color: var(--primary-color);
}

.showHousingThirdSectionMargin {
  margin: 0px 17px 0px 0px;
}
.showHousingIcon i {
  font-size: 21px;
  margin: 0px 7px;
  color: var(--primary-color);
  cursor: pointer;
}

.showHousingTitle h3 {
  color: var(--primary-color);
}
.showHousingAddress {
  color: var(--primary-color);
}
.showHousingGalleryDiv {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  padding-top: 10px;
}
.showHousingGalleryDiv img {
  /* height: 125px;
  width: 185px; */
  height: 131px;
  width: 206px;
  margin-bottom: 30px;
  object-fit: cover;
  border-radius: 12px;
  cursor: pointer;
  margin: 10px;
}
.showHousingMarker {
  /* color: #ca9800; */
  color: var(--secondary-color);
}

.thirdSectionIcon {
  padding: 0px 6px;
}

.lastSectionDiv {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.priceParagraph {
  color: var(--secondary-highlight-color);
  font-size: 30px;
  font-weight: bold;
}

@media (max-width: 350px) {
  .showHousingImageDiv img {
    /* z-index: 5; */
    width: 100%;
    height: 343px;
    object-fit: cover;
    box-shadow: 0px 16px 32px rgba(47, 26, 14, 0.28);

    position: relative;
  }

  .showHousingThirdSection {
    background: rgba(10, 68, 34, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 6px;
    margin-bottom: 28px;
  }
  .showHousingGalleryDiv img {
    height: 123px;
    width: 143px;
  }
  .showHousingIconParagraph {
    width: 69px;
  }
  .showHousingThirdSectionMargin {
    margin: 0px;
  }
}

@media (min-width: 350px) and (max-width: 480px) {
  .showHousingImageDiv img {
    /* z-index: 5; */
    width: 100%;
    height: 343px;
    object-fit: cover;
    box-shadow: 0px 16px 32px rgba(47, 26, 14, 0.28);
    position: relative;
  }

  .showHousingThirdSection {
    background: rgba(10, 68, 34, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 6px;
    margin-bottom: 28px;
  }
  .showHousingGalleryDiv img {
    height: 123px;
    width: 143px;
  }
  .showHousingIconParagraph {
    width: 71px;
  }
  .showHousingThirdSectionMargin {
    margin: 0px;
  }
}

@media (max-width: 360px) {
  .showHousingGalleryDiv img {
    height: 114px;
    width: 132px;
  }
  .showHousingTitle h3 {
    font-size: 20px;
  }
}

@media (min-width: 320px) and (max-width: 340px) {
  .showHousingGalleryDiv img {
    height: 114px;
    width: 278px;
  }
}

@media (max-width: 990px) {
  .showHousingInfoDiv {
    background: #ffffff;
    box-shadow: none;
    /* z-index: 2; */
    border-radius: 0px 0px 34px 32px;
  }
  .showHousingThirdSection {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 380px) {
  .showHousingThirdSection {
    justify-content: space-between;
  }
  .showHousingIconParagraph {
    width: 80px;
  }
}
@media (max-width: 480px) {
  .showHousingGalleryDiv{
    display: flex;
    justify-content: space-between;
  }
  .showHousingGalleryDiv img{
    margin: 0px ;
    margin-bottom: 30px;
  }
}